import React from "react"
import styled from "styled-components"
import { device, gradientText } from "utils"
import { matrixData } from "utils/api/matrix"
import CheckIcon from "images/common/check-arrow.inline.svg"
import Logo from "images/nav/deepwatersLogo.inline.svg"
import {VSection} from "components/common"
const ComparativeMatrix = ({ title }) => {
  const sectionRenderTable = () => (
    <figure className="matrixContainer is-style-stripes">
      <table>
        <tbody>
          <tr>
            <td className="has-text-align-center header" data-align="center">
              <strong>Exchange Comparison</strong>
            </td>
            <td className="has-text-align-center header" data-align="center">
              <strong>CEX</strong>
              {/*<strong>Centralized</strong>*/}
            </td>
            <td className="has-text-align-center header" data-align="center">
              <strong>DEX</strong>
              {/*<strong>Decentralized</strong>*/}
            </td>
            <td className="has-text-align-center" data-align="center">
              <Logo className='logo-icon'/>
            </td>
          </tr>
          {matrixData.map((row, index) => {
            const { matrixName, cexValue, dexValue, hoverInfo, deepwaterValue } = row
            return (
              <tr key={`${index}-${matrixName}`}>
                <td className="has-text-align-center" data-align="center">
                  {matrixName} {hoverInfo === "x" ? " " : <span title={hoverInfo}> (?) </span>}
                </td>
                <td className="has-text-align-center" data-align="center">
                  {cexValue === true ? "✔" : cexValue === false ? "" : cexValue}
                </td>
                <td className="has-text-align-center" data-align="center">
                  {dexValue === true ? "✔" : dexValue === false ? "" : dexValue}
                </td>
                <td className="has-text-align-center deepwatersText" data-align="center">
                  {deepwaterValue === true ? (
                      <div className="checkIcon">
                          <CheckIcon className='logo-icon'/>
                      </div>
                  ) : (
                    deepwaterValue
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </figure>
  )

  return (
    <StyledHomeMatrix>
      <VSection
        id="comparative_matrix"
        custom={sectionRenderTable}
      />
    </StyledHomeMatrix>
  )
}

export default ComparativeMatrix

const StyledHomeMatrix = styled.div`
  --section-align: center;
  --tg-text-align: center;
  position: relative;
  z-index: 2;

  --section-max-width: 1200px;

  --card-animation-duration: 20s;
  
  .matrixContainer {
    --section-max-width: 1200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background: var(--card-background);
    border-radius: var(--card-radius);
    --section-align: center;
    overflow: hidden;
    --section-mobile-order: auto;

    &.is-style-stripes {
      td {
        border-color: transparent;
      }
      tr > td {
        padding-top: 15px;
        padding-bottom: 15px;
        &:not(:last-child) {
          border-color: transparent #08101fba transparent transparent;
        }
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
      line-height: 1.5;
      font-size: 10px;
      @media ${device.mobileM} {
        font-size: 12px;
      }

      @media ${device.laptop} {
        font-size: 18px;
      }
     
      tbody {
        tr {
          &:nth-child(odd) {
            background: var(--card-background);
          }
          td {
            padding: 0.5em;
            border: 1px solid;
            word-break: normal;
            &.header {
              h3{
                @media ${device.mobileM} {
                  font-size: inherit;
                }
              }
              &:first-child {
                ${gradientText(
                  "linear-gradient(97.86deg, #D556E0 -13.44%, #E86584 108.56%)"
                )};
              }
              strong {
                &:first-child {
                  font-size: inherit;
                }
                letter-spacing: 0.15rem;
              }
            }
            .logo-icon{
              width: 6rem;
              height: auto;
              margin: 0 auto;
              display: block;
              vertical-align: middle;
              @media ${device.mobileM} {
                width: 6rem;
              }

              @media ${device.laptop} {
                width: 10rem;
              }
            }
            .check-icon {
              width: 1.5rem;
              height: 1.5rem;
              margin: 0 auto;
              display: block;
              vertical-align: middle;
            }
            &.has-text-align-center {
              text-align: center;
              display: table-cell;
              vertical-align: middle;
              &.deepwatersText{
                .checkIcon{
                    width: 2rem;
                    box-shadow: var(--card-shadow);
                    height: 2rem;
                    margin: 0 auto;
                  border-radius: 50%;
                    background:linear-gradient(91.39deg,#C99950 -12.56%,#D15BC5 116.76%);
                  display: flex;
                    align-items: center;
                  .logo-icon{
                    width: 1.5rem;
                    height:auto;
                  }
                }
                //color: rgb(241, 118, 198);
              }
            }
          }
          > td:first-child {
            padding: 1.25rem;
            text-align: left;
          }
        }
      }
    }
  }
`
