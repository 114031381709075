export const matrixData = [
  {
    matrixName: "Zero Gas Trading",
    cexValue: true,
    dexValue: false,
    deepwaterValue: true,
    hoverInfo: "x"
  },
  {
    matrixName: "Zero Slippage",
    cexValue: true,
    dexValue: false,
    deepwaterValue: true,
    hoverInfo: "x"
  },
  {
    matrixName: "Zero Front-Running" ,
    cexValue: false,
    dexValue: false,
    deepwaterValue: true,
    hoverInfo: "x"
  },
  {
    matrixName: "Zero Payment for Order Flow",
    cexValue: false,
    dexValue: true,
    deepwaterValue: true,
    hoverInfo: "Payment for order flow is a practice where a broker or centralized exchange receives payment to route all orders to a particular market maker, who gets to act on all the orders before anyone else. This benefits the market maker while increasing adverse selection for traders. Deepwaters does not and cannot do this."
  },
  {
    matrixName: "Zero Internalization",
    cexValue: false,
    dexValue: true,
    deepwaterValue: true,
    hoverInfo: "Some exchanges will internally arbitrage orders and keep the profit. This is internalization. In Deepwaters, we cannot take discretionary actions with the orders, and cannot even see the orders until they hit the public order book."
  },
  {
    matrixName: "Zero Rehypothecation",
    cexValue: false,
    dexValue: true,
    deepwaterValue: true,
    hoverInfo: "Rehypothecation is what banks do - take user assets and put them to use for other uses at their discretion in order to make money. It is what makes bank runs possible and why FDIC insurance exists. Exchanges are not banks and crypto deposits are not insured. When exchanges rehypothecate assets, they are taking risks that the customers will end up paying for if things go wrong. This is what happened with Voyager and FTX. In Deepwaters, we are unable to take discretionary actions with user assets. Users retain full control over their own funds at all times."
  },
  {
    matrixName: "Zero Data Bleed ",
    cexValue: false,
    dexValue: false,
    deepwaterValue: true,
    hoverInfo: "Data bleed refers to unintentional data leakage which allows certain market participants to 'see the future' - to know what will happen slightly into the future. This is normally due to sophisticated parties being able to see the order flow before it hits the order books (or, in DeFi, before the transactions make it into the blocks). This gives certain parties an advantage of information over everyone else. Deepwaters' cryptographically secure operations prevent the possibility of this entirely, making trading more fair for everyone."
  },
  {
    matrixName: "100% Price Improvement",
    cexValue: false,
    dexValue: false,
    deepwaterValue: true,
    hoverInfo: "Price improvement occurs when an order 'crosses' the market - when the lowest ask is below the highest bid - and therefore your order executes at a better price than anticipated. Some exchanges (or the market makers paying for their order flow) pocket the difference instead of giving you that price improvement. In Deepwaters, you get 100% of the price improvement, all the time."
  },
  {
    matrixName: "Fully Transparent" ,
    cexValue: false,
    dexValue: true,
    deepwaterValue: true,
    hoverInfo: "x"
  },
  {
    matrixName: "Real-Time Auditing",
    cexValue: false,
    dexValue: true,
    deepwaterValue: true,
    hoverInfo: "x"

  },
  {
    matrixName: "Self Custody",
    cexValue: false,
    dexValue: true,
    deepwaterValue: true,
    hoverInfo: "x"
  },
]
