import React from "react"
import styled from "styled-components"
import { AnimatedButton, Cta } from "src/components/common"

import { device } from "utils"
import { Link } from "components/common"

const TestNetCtaGroup = ({ ctas }) => {
  return (
    <StyledTestNetCtaGroup className="textNet-cta">
      <AnimatedButton className="button">
        <a href="https://app.deepwaters.xyz/kyc" target="_blank">
          <strong>Trade Now</strong>
        </a>
      </AnimatedButton>
    </StyledTestNetCtaGroup>
  )
}

const StyledTestNetCtaGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: var(--sp-16);

  .button span {
    background: none;
  }

  a > strong {
    color: #e7e9f1;
  }
  @media ${device.laptop} {
    /* width: 100%; */
    grid-template-columns: auto;
  }
  /* discord image  */
  img {
    margin-right: 8px;
  }
  .buttonRef {
    background: linear-gradient(
      175.71deg,
      rgba(24, 55, 100, 0.7) -14.71%,
      rgba(6, 22, 45, 0.7) 120.85%
    );
    box-shadow: 0px 2px 8px rgba(112, 112, 243, 0.22),
      inset -1px -1px 2px rgba(132, 93, 223, 0.82),
      inset 1px 1px 2px rgba(100, 146, 243, 0.82);

    padding: var(--sp-16) var(--sp-24);
    border-radius: 30px;
    color: #5077c9;
    line-height: 1.5;
  }
`

export default TestNetCtaGroup
